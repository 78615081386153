export default function readyUpForVersus(state = { ready: false }, action) {
    switch (action.type) {
        case "READY_UP_FOR_VERSUS_REQUEST":
            console.log('hihihi')
            return { ...state, ready: true };
        case "READY_UP_FOR_VERSUS_SUCCESS":
            return { ...state, ready: true };
        case "READY_UP_FOR_VERSUS_FAILURE":
            return { ...state, ready: false };
        default:
            return state
    }
}