import io from 'socket.io-client';
import gameActions from '../actions/game.actions'
import userActions from '../actions/user.actions'
import store from '../storeProvider'

const API_SOCKET_URL_ = process.env.REACT_APP_BACKEND_URL;

// Example conf. You can move this to your config file.
const host = API_SOCKET_URL_;

export default class socketAPI {
  connect() {
    const regex = /(")+/g
    const token = localStorage.getItem('token')
    if (!token) {
        return;
    }
    const connectionOptions =  {
      forceNew: true,
      reconnectionAttempts: "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
      timeout: 10000,     
      auth: { token: `Bearer ${token.replace(regex, '')}` }     //before connect_error and connect_timeout are emitted.
    };
  
    this.socket = io(host, connectionOptions);
    return new Promise((resolve, reject) => {
      this.socket.on('connect', () => {
        if (localStorage.getItem('token')) {
          const regex = /(")+/g
          const jwt = token.replace(regex, '');
     //     this.socket
      //      .emit('authenticate', { token: jwt })
      //      .on('authenticated', () => {console.log('JWT Authenticated.')})
        }

        resolve();
      });
      this.socket.on('connect_error', (error) => reject(error));
    });
  }

  disconnect() {
    return new Promise((resolve) => {
      this.socket.on('disconnect', () => {this.socket = null})

      this.socket.close();
      resolve();
    });
  }

  emit(event, data) {
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject('No socket connection.');

      return this.socket.emit(event, data, (response) => {
        // Response is the optional callback that you can use with socket.io in every request. See 1 above.
        if (response.error) {
          console.error(response.error);
          return reject(response.error);
        }

        if (response) {
          return resolve(response);
        }
        
        return resolve();
      });
    });
  }

  listenForGameEnd() {
    return new Promise((resolve, reject) => {
      this.socket.on('game-over', () => {
        store.dispatch({type: "END_GAME_STATE"}); 
      });

      resolve();
    })
  }

  setupVersusEvents() {
    return new Promise((resolve, reject) => {
        this.socket.once('game-start', () => {
            store.dispatch({type: "GAME_START_TRIGGERED"}); 
        });
        this.socket.on('opponent-update', (event, func) => {
            store.dispatch({type: "OPPONENT_UPDATE_TRIGGERED", event}); 
        });
        resolve();
    })
  }

  setupAppWideEvents() {
    return new Promise((resolve, reject) => {
        this.socket.on('game-request', (requestDetails) => {
            console.log('game requested')
            store.dispatch({type: "GAME_REQUEST_TRIGGERED", payload: requestDetails}); 
        });

        this.socket.on('user-connected', (requestDetails) => {
            store.dispatch({type: "USER_CONNECTED", payload: requestDetails}); 
        });

        this.socket.on('user-disconnected', (requestDetails) => {
            store.dispatch({type: "USER_DISCONNECTED", payload: requestDetails}); 
        });

        store.dispatch(userActions.getActiveUsers()); 
        resolve();
    })
  }

  on(event, fun) {
    console.log('RUNNING')
    // No promise is needed here, but we're expecting one in the middleware.
    return new Promise((resolve, reject) => {
      if (!this.socket) return reject('No socket connection.');

      this.socket.on(event, fun);
      resolve();
    });
  }
}
