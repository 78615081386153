export default function getActiveUsers(state = { users: [] }, action) {
    switch(action.type) {
      case 'GET_ACTIVE_USERS':
        return {
          ...state,
          isSending: true,
        };
      case 'GET_ACTIVE_USERS_SUCCESS':
        return {
          ...state,
          isSending: false,
          users: action.result, 
        }
        case 'GET_ACTIVE_USERS_FAILURE':
            return {
              ...state,
              isSending: false,
              error: action.result, 
            }
        case 'USER_CONNECTED':
            return {    
                ...state,
                users: [...state.users, action.payload]
            }
        case 'USER_DISCONNECTED':
            return {
                ...state,
                users: state.users.filter((user) => user.userId !== action.payload.userId)
            }
      default: {
        return state;
      }
    }
  }