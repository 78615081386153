import React from 'react';
import {
  Link
} from 'react-router-dom';

import logo from '../../images/logo.svg'


function LandingHeader() {
  return (
    <div className="header-container landing-header">
      <Link to="/" className="speedtyper-word"><img src={logo} height="30px" className="logo"></img>SpeedTyper</Link>
      <div className="spacer"></div>
      <div className="promo">
        Powered by <a href="http://linkedin.com/in/gray-lewis">TANDEM</a>.
      </div>
    </div>
  );
}

export default LandingHeader;