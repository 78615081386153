import React from 'react';
import {
    Link
} from 'react-router-dom';

import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from 'redux-form';
import userActions from '../../actions/user.actions'

class RegisterForm extends React.Component {
    doSubmit = (user) => {
        return this.props.registerUser(user).then((res) => {
            if (res.errors) {
                return Promise.reject(res);
            } else if (res.message) {
                return Promise.resolve(res.message);
            }
        })
    }

    render() {
        const { error, handleSubmit, success } = this.props;

        return (
            <>
                <div className="registration-form">
                    <div className="registration-title">Register for an Account</div>
                    <form className="general-form" onSubmit={handleSubmit(this.doSubmit)}>
                        <Field className="general-input registration-input" component="input" type="email" placeholder="Email" name="email"></Field>
                        <Field component="input" type='password' className="general-input registration-input password-input" placeholder="Password" name="password"></Field>
                        <div className="row">
                            <Field className="general-input registration-input registration-input-half" component="input" type="text" placeholder="First Name" name="firstName"></Field>
                            <Field className="general-input registration-input registration-input-half" component="input" type="text" placeholder="Last Name" name="lastName"></Field>
                        </div>
                        <div className="tos-container">
                            <Field component="input" name="terms" type="checkbox" />
                            <label htmlFor="terms">I agree to the terms of service.</label>
                        </div>
                        {error &&
                            <strong>{
                                <div className="error-container">{error}</div>
                            }</strong>}
                        {success &&
                            <strong>{
                                <div className="success-container">An verification email has been sent to the specified address.</div>
                            }</strong>}
                        <button component="input" className="submit-button" id="submit-button" type="submit">Submit</button>
                        <div className="login-link">
                            Already have an account?&nbsp;
                            <Link to="/login">Log In</Link>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

RegisterForm = reduxForm({
    form: 'register',
})(RegisterForm)


//TODO: Figure out how to get the success variable from the state. 
function mapStateToProps(state) {
    const { registering, success } = state.registerUser;

    return {
        registering,
        success,
    };
}

const actionCreators = {
    registerUser: userActions.registerUser,
}

export default connect(mapStateToProps, actionCreators)(RegisterForm);