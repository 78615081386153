function updateTimer(time) {
  return (dispatch) => {
    dispatch({ type: 'UPDATE_TIMER', time })
  }
}

function endGame() {
  return (dispatch) => {
    dispatch({ type: "END_GAME_STATE" })
  }
}

function updateGameState(update) {
    return (dispatch) => {
        dispatch({ type: 'UPDATE_GAME_STATE', update})
    }
}

function cleanGameState() {
  return (dispatch) => {
    dispatch({ type: 'CLEAN_GAME_STATE'})
  }
}

function connectToGame(gameId) {
    return {
        type: 'socket',
        types: ['CONNECT_TO_GAME', 'CONNECT_TO_GAME_SUCCESS', 'CONNECT_TO_GAME_FAIL'],
        promise: (socket) => socket.emit('game-id', gameId),
    }
}

function readyUpForVersus(gameId) {
    return {
        type: 'socket',
        types: ['READY_UP_FOR_VERSUS_REQUEST', 'READY_UP_FOR_VERSUS_SUCCESS', 'READY_UP_FOR_VERSUS_FAIL'],
        promise: (socket) => socket.emit('ready-up', gameId),
    }
}

function listenForGameEnd() {
    return {
        type: 'socket',
        types: ['LISTEN_FOR_GAME_END', 'LISTEN_FOR_GAME_END_SUCCESS', 'LISTEN_FOR_GAME_END_FAIL'],
        promise: (socket) => socket.listenForGameEnd(),
    }
}

function setupVersusEvents() {
    return { 
        type: 'socket',
        types: ['SETUP_VERSUS_EVENTS', 'SETUP_VERSUS_EVENTS_SUCCESS', 'SETUP_VERSUS_EVENTS_FAIL'],
        promise: (socket) => socket.setupVersusEvents(),
    }
}

function setupAppWideEvents() {
    return { 
        type: 'socket',
        types: ['SETUP_APP_WIDE_EVENTS', 'SETUP_APP_WIDE_EVENTS_SUCCESS', 'SETUP_APP_WIDE_EVENTS_FAILURE'],
        promise: (socket) => socket.setupAppWideEvents(),
    }
}

function sendGameUpdate(id, input) {
    return {
        type: 'socket',
        types: ['SEND_GAME_UPDATE', 'SEND_GAME_UPDATE_SUCCESS', 'SEND_GAME_UPDATE_FAIL'],
        promise: (socket) => socket.emit('update', { id, update: input }),
    }
}

function generateStatistics(events) {
  return (dispatch) => {
    let averageWPM = 0;
    let charactersTyped;
    let mistakeCount;
    if (events) {
      const onlyAdditions = events.filter((val) => val.addition);
      const onlyMistakes = events.filter((val) => !val.addition);

      averageWPM = onlyAdditions.length / 5;
      charactersTyped = onlyAdditions.length;
      mistakeCount = onlyMistakes.length;
    }

    const stats = {
      averageWPM,
      charactersTyped,
      mistakeCount
    }

    dispatch({ type: 'GENERATE_STATISTICS', stats })
  }
}

const actionCreators = {
  generateStatistics,
  connectToGame,
  readyUpForVersus,
  sendGameUpdate,
  updateGameState,
  updateTimer,
  endGame,
  cleanGameState,
  listenForGameEnd,
  setupVersusEvents,
  setupAppWideEvents
}

export default actionCreators;