import { useDispatch } from 'react-redux';
import userActions from '../../actions/user.actions';

function ActiveUserRow({ user }) {
    const dispatch = useDispatch();
    return (
        <li className="active-user-row" key={user.id}>
            <span className="active-user-row-name">{user.name}</span>
            <button className="active-user-row-button" onClick={() => dispatch(userActions.createVersusGame(localStorage.getItem('token'), user.id))}> Challenge</button>
        </li>
    )
}

export default ActiveUserRow;