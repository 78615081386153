export default function createVersusGame(state = {}, action) {
    switch (action.type) {
        case "CREATE_VERSUS_GAME_REQUEST":
            return { game: null };
        case "CREATE_VERSUS_GAME_SUCCESS":
            return { game: action.gameId };
        case "CREATE_VERSUS_GAME_FAILURE":
            return {};
        case "CREATE_VERSUS_GAME_JOINED":
            return { game: null }
        default:
            return state
    }
  }