// A functional component called Keyboard that renders the keys of a keyboard

import React, { useCallback, useState } from "react"

const SquareKey = React.memo(({value, active, onPress}) => {
    return (
        <div key={value} onClick={() => {onPress(value)}} className={`${(active) ? 'active':''} key square-key outer-key`}>
            <div className="inner-key">
                {value}
            </div>
        </div>
    )
})

const FunctionKeyLevel1 = React.memo(({value, active}) => {
    console.log('rerendered')
    return (
        <div key={value} className="key outer-key function-lvl-1-key">
            <div className="inner-key">
                {value}
            </div>
        </div>
    )
})

const FunctionKeyLevel2 = React.memo(({value, active}) => {
    return (
        <div key={value} className="key outer-key function-lvl-2-key">
            <div className="inner-key">
                {value}
            </div>
        </div>
    )
})

const FunctionKeyLevel3 = React.memo(({value, active}) => {
    return (
        <div key={value} className="key outer-key function-lvl-3-key">
            <div className="inner-key">
                {value}
            </div>
        </div>
    )
});

const FunctionKeyLevel4 = React.memo(({value, active}) => {
    return (
        <div key={value} className="key outer-key function-lvl-4-key">
            <div className="inner-key">
                {value}
            </div>
        </div>
    )
});

const SpaceBarKey = React.memo(({ active }) => {
    return (
        <div key={"Space"} className={`${(active) ? 'active':''} key outer-key space-bar-key`}>
            <div className="inner-key">
            </div>
        </div>
    )
})

export default function Keyboard(props) {

    const { pressed, setPressed, onPressKey } = props;

    return (
        <div className="keyboard">
            <div className="key-row">
                <SquareKey onPress={onPressKey} active={pressed.has('`')} value="`"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('1')} value="1"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('2')} value="2"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('3')} value="3"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('4')} value="4"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('5')} value="5"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('6')} value="6"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('7')} value="7"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('8')} value="8"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('9')} value="9"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('0')} value="0"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('-')} value="-"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('+')} value="+"></SquareKey>
                <FunctionKeyLevel2 value="Delete"></FunctionKeyLevel2>
            </div>
            <div className="key-row">
                <FunctionKeyLevel2 value="Tab" onPress={onPressKey} active={pressed.has('Tab')} ></FunctionKeyLevel2>
                <SquareKey onPress={onPressKey} active={pressed.has('Q')} value="Q"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('W')} value="W"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('E')} value="E"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('R')} value="R"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('T')} value="T"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('Y')} value="Y"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('U')} value="U"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('I')} value="I"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('O')} value="O"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('P')} value="P"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('[')} value="["></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has(']')} value="]"></SquareKey>
                <FunctionKeyLevel2 onPress={onPressKey} value="\" active={pressed.has('\\')}></FunctionKeyLevel2>
            </div>
            <div className="key-row">
                <FunctionKeyLevel3 value="Caps" onPress={onPressKey} active={pressed.has('Caps')} ></FunctionKeyLevel3>
                <SquareKey onPress={onPressKey} active={pressed.has('A')} value="A"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('S')} value="S"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('D')} value="D"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('F')} value="F"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('G')} value="G"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('H')} value="H"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('J')} value="J"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('K')} value="K"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('L')} value="L"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has(';')} value=";"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('\'')} value="'"></SquareKey>
                <FunctionKeyLevel3 value="Enter"></FunctionKeyLevel3>
            </div>
            <div className="key-row">
                <FunctionKeyLevel4 value="Shift" onPress={onPressKey} active={pressed.has('Shift')} ></FunctionKeyLevel4>
                <SquareKey onPress={onPressKey} active={pressed.has('Z')} value="Z"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('X')} value="X"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('C')} value="C"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('V')} value="V"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('B')} value="B"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('N')} value="N"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('M')} value="M"></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has(',')} value=","></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('.')} value="."></SquareKey>
                <SquareKey onPress={onPressKey} active={pressed.has('/')} value="/"></SquareKey>
                <FunctionKeyLevel4 value="Shift"></FunctionKeyLevel4>
            </div>
            <div className="key-row">
                <FunctionKeyLevel1 value="Ctrl"></FunctionKeyLevel1>
                <FunctionKeyLevel1 value="Win"></FunctionKeyLevel1>
                <FunctionKeyLevel1 value="Alt"></FunctionKeyLevel1>
                <SpaceBarKey active={pressed.has('Space')}></SpaceBarKey>
                <FunctionKeyLevel1 value="Alt"></FunctionKeyLevel1>
                <FunctionKeyLevel1 value="Win"></FunctionKeyLevel1>
                <FunctionKeyLevel1 value="Ctrl"></FunctionKeyLevel1>
            </div>
        </div>
    );
}