import React from 'react';
import { connect, shallowEqual, useDispatch, useSelector } from "react-redux";

import { Link } from 'react-router-dom';

import MatchHistory from '../molecules/MatchHistory';
import Leaderboard from '../molecules/Leaderboard';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import gameActions from '../../actions/game.actions';
import userActions from '../../actions/user.actions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ActiveUserRow from '../molecules/ActiveUserRow';

function Welcome() {
    const history = useHistory();
    const {
        versusGame,
        activeUsers,
        gameRequest,
        gameRequestDetails,
        user
    } = useSelector((state) => { 
        let versusGame = null;
        if (state.createVersusGame.game) {
            versusGame = state.createVersusGame.game.gameId;
        } 
        return {
            versusGame, 
            user: state.authUser.user,
            activeUsers: state.getActiveUsers.users,
            gameRequest: state.appWideEvents.gameRequest,
            gameRequestDetails: state.appWideEvents.gameRequestDetails
        }}, 
    shallowEqual)

    const dispatch = useDispatch();

    const verify = (jwt) => {
        return dispatch(userActions.authUser(jwt)).then((res) => {
            if (res.errors) {
                return Promise.reject(res);
            } else if (res.message) {
                return Promise.resolve(res.message);
            }
        })
    }

    React.useEffect(() => {
        if (localStorage.getItem('token')) {
            const token = localStorage.getItem('token');
            verify(token)
                .catch((error) => {
                    throw error;
                })
        }
    }, []) 

    return (
        <div>
            { versusGame !== null &&
                <Redirect to={`/versus/${versusGame}`} />
            }
            {user &&
                <>
                    <div className="welcome-container">
                        <div className="welcome-cards">
                            <div className="welcome-cards-left">
                                <div className="welcome-profile-card welcome-card">
                                    <div className="welcome-title">
                                        Welcome, {`${user.firstName} ${user.lastName}`}
                                    </div>
                                    <div className="profile-tags">
                                        <span className="tag">Joined on {new Date(user.createdAt).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</span>
                                        <span className="tag">Verified Account</span>
                                    </div>
                                    <div className="profile-disclaimer">
                                        Thank you for checking out an early version of SpeedTyper!<br></br><br></br>
                                        Many features (almost all of them) are still missing, such as multiplayer racing and publicly facing profiles. These features are coming, but in the mean time, try out the beta version of the practice mode. <br></br><br></br>
                                        - Gray Lewis
                                    </div>
                                </div>
                                <Link to="/play">
                                    <div className="create-game">
                                        START PRACTICE MODE
                                    </div>
                                </Link>
                                {
                                    gameRequest &&
                                    <div className="welcome-card accept-game-request">
                                        <div className="accept-game-request-text welcome-title">
                                            {gameRequestDetails.opponent.name} has challenged you to a game!
                                        </div>
                                        <div className="accept-game-request-buttons">
                                            <button className="accept-game-request-button accept-game-request-button-accept" onClick={() => { dispatch({ type: 'GAME_REQUEST_ACCEPTED' }); history.push(`/versus/${gameRequestDetails.gameId}`)}}>Accept</button>
                                            <button className="accept-game-request-button accept-game-request-button-deny" onClick={() => { dispatch({ type: 'GAME_REQUEST_REJECTED' }); }}>Decline</button>
                                        </div>
                                    </div>
                                }
                                <div className="welcome-active-users welcome-card">
                                    {activeUsers &&
                                        <ul>
                                            <div class="welcome-title">
                                                Active Users
                                            </div>
                                            {activeUsers.map((user) => {
                                                return <ActiveUserRow user={user}></ActiveUserRow>
                                            })}
                                            { activeUsers.length === 0 && 
                                                <div className="no-active-users">
                                                    No active users
                                                </div>
                                            }
                                        </ul>
                                    }
                                </div>
                            </div>
                            <div className="welcome-cards-right">
                                <div className="welcome-history-card welcome-card">
                                    <div className="welcome-title">
                                        Match History
                                    </div>
                                    <MatchHistory></MatchHistory>
                                </div>
                                <div className="welcome-leaderboard-card welcome-card">
                                    <div className="welcome-title">
                                        Leaderboard
                                    </div>
                                    <Leaderboard></Leaderboard>
                                </div>
                            </div>
                        </div>

                    </div>

                </>
            }
        </div>
    );
}

export default Welcome;