export default function setupVersusEvents(state = {}, action) {
    switch(action.type) {
        case 'SETUP_VERSUS_EVENTS':
            return {
                ...state,
                started: false,
            };
        case 'SETUP_VERSUS_EVENTS_SUCCESS':
            return state
        case 'SETUP_VERSUS_EVENTS_TRIGGERED':
            return state
        case 'GAME_START_TRIGGERED':
            return {
                ...state,
                started: true,
            }
        case 'OPPONENT_UPDATE_TRIGGERED':
            return {
                ...state,
                opponentAccurateTo: action.event.accurateTo,
            }
        default: {
            return state;
      }
    }
  }