import React from 'react';
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import RoundedTitle from '../atoms/RoundedTitle';
import ChallengeRow from '../atoms/ChallengeRow';
import userActions from '../../actions/user.actions';
import gameActions from '../../actions/game.actions';

import ClipLoader from "react-spinners/ClipLoader";

//Framer workaround
import { motion } from 'framer-motion'
import createPracticeGame from '../../reducers/createPracticeGame';
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

class TypingGamePractice extends React.Component {
    constructor(props) {
        super(props);
        this.timeoutID = null;
        this.state = {
            currentAttempt: '',
            lines: [],
            activeLine: 0,
            completedLinesLength: 0,
            gameOver: false,
            started: false,
        }
    }

    flattenToString(arrayOfWords) {
        return arrayOfWords.reduce((acc, val) => `${acc} ${val}`) + ' ';
    }

    flattenArray(arrayOfLines) {
        return arrayOfLines.reduce((acc, val) => acc.concat(val))
    }

    async componentDidMount() {
        if (this.timeoutID) { clearTimeout(this.timeoutID) }
        if (!localStorage.getItem('token')) { return }

        const jwt = localStorage.getItem('token');
        const gameId = await this.props.createPracticeGame(jwt)
        const resp = await this.props.connectToSocket()
        console.log(resp)
        let game = await this.props.connectToGame(gameId.gameId);

        if (!game) { throw new Error('Error while creating game') }

        // GAME INIT
        this.setState({
            lines: this.divideIntoLines(this.props.challenge),
        })

        this.props.updateTimer(60);
        const typeCapture = document.getElementsByClassName("type-capture")[0];
        typeCapture.addEventListener('input', async (inputObj) => {
            if (!this.state.started) {
                const gameEnd = new Date()
                gameEnd.setSeconds(gameEnd.getSeconds() + 60)

                const checkTimeout = () => {
                    const now = new Date();
                    if (now > gameEnd && this.timeoutID) {
                    } else {
                        this.props.updateTimer(Math.ceil((gameEnd.getTime() - now.getTime()) / 1000))
                        this.timeoutID = setTimeout(checkTimeout, 1000)
                    }
                }

                this.timeoutID = setTimeout(checkTimeout, 1000)
                this.props.listenForGameEnd();
                this.setState({ started: true })
            }

            if (inputObj.isTrusted) {
                const wordArray = inputObj.target.value.split(' ');
                const activeLine = this.divideIntoLines(this.props.challenge.slice(0, wordArray.length)).length - 1;

                const completedLinesLength = (activeLine > 0 ? this.flattenToString(this.flattenArray(this.state.lines.slice(0, activeLine))).length : 0)
                this.setState(
                    {
                        activeLine,
                        completedLinesLength
                    })

                this.setState({
                    currentAttempt: inputObj.target.value
                })

                const response = await this.props.sendGameUpdate(gameId.gameId, inputObj.target.value)
                this.props.updateGameState(response)
            }
        })
        typeCapture.focus()
        const speedTyperContainer = document.querySelector('.speed-typer-container');
        speedTyperContainer.addEventListener('click', () => typeCapture.focus())
    }

    getCurrentWord() {
        if (this.props.accurateTo) {
            const currentlyAccurate = this.props.challenge.join(' ').slice(0, this.props.accurateTo)
            const amountOfSpaces = currentlyAccurate.match(/ /g)
            if (amountOfSpaces) { return amountOfSpaces.length } else { return 0 }
        } else {
            return 0
        }
    }

    sentenceLength(arrayOfWords) {
        const length = arrayOfWords.reduce((acc, current) => {
            return acc += current.length + 1
        }, 0)
        return length;
    }

    componentWillUnmount() {
        clearTimeout(this.timeoutID);
        this.props.cleanGameState();
        this.props.disconnectFromSocket()
            .then(() => console.log('DISCONNECTED'))
    }

    divideIntoLines(words) {
        const lines = [];

        words.forEach((word) => {
            const wordWithSpace = `${word} `
            if (lines[0] && (this.sentenceLength(lines[0]) + wordWithSpace.length) <= 40) {
                lines[0].push(word)
            } else {
                lines.unshift([word])
            }
        })

        return lines.reverse();
    }

    renderOldWords(arrayOfLines) {
        const arrayOfRenderedLines = arrayOfLines.map((line, index) => {
            const arraysOfLetters = line.map((word, index) => {
                const arraysOfLetters = word.split('')
                if (index !== arrayOfLines.length - 1) { arraysOfLetters.push(' ') }
                return arraysOfLetters
            }, [])
            const arrayOfRenderedWords = arraysOfLetters.map((word, index) => {
                const renderedLetters = word.map((letter, index) => {
                    return <div key={index} className="challenge-letter">{letter}</div>
                })
                return <div key={index} className='word'>{renderedLetters}</div>;
            })

            const renderedLine = <div key={index} className='challenge-row'> {arrayOfRenderedWords} </div>
            return renderedLine;
        })

        return arrayOfRenderedLines
    }

    /*  let renderedLetters = word.map((letter, index) => {
        const overallIndex = oldWordsLetterCount + index;
        console.log(`INDEX: ${index} overallIndex: ${overallIndex}`)
        
        let classString = 'challenge-letter'
        if (overallIndex === syncedTo) {
          classString = `active-letter ${classString}`
        }
        else if (overallIndex < accurateTo) {
          classString = `accurate ${classString}`
        }
        else if (overallIndex >= accurateTo && overallIndex <= syncedTo && this.state.currentAttempt.length > accurateTo && overallIndex <= this.state.currentAttempt.length) {
          classString = `inaccurate ${classString}`
          return <div key={index} className={classString}>{ this.state.currentAttempt[overallIndex] }</div> 
        }
        return <div key={index} className={classString}>{ letter }</div> 
        })
      
      return (<div className='word active-word'>{ renderedLetters }</div>)*/

    renderActiveLine(line, accurateTo, syncedTo) {
        let overallIndex = 0;

        const arrayOfRenderedWords = line.map((word, index) => {
            const arrayOfLetters = word.split('')
            arrayOfLetters.push(' ')

            const lineAccurateTo = accurateTo - this.state.completedLinesLength;
            const lineCurrentAttemptLength = this.state.currentAttempt.length - this.state.completedLinesLength;
            const renderedLetters = arrayOfLetters.map((letter, index) => {
                let classString = 'challenge-letter'
                if (overallIndex === lineCurrentAttemptLength) {
                    classString = `active-letter ${classString}`
                }
                if (overallIndex < lineAccurateTo) {
                    classString = `accurate ${classString}`
                }
                if (overallIndex >= lineAccurateTo && this.state.currentAttempt[overallIndex + this.state.completedLinesLength] && (overallIndex < syncedTo - this.state.completedLinesLength)) {
                    classString = `inaccurate ${classString}`
                }

                overallIndex += 1;
                return <div key={index} className={classString}>{((overallIndex > lineAccurateTo && this.state.currentAttempt[overallIndex + this.state.completedLinesLength - 1]) ? this.state.currentAttempt[overallIndex + this.state.completedLinesLength - 1] : letter)}</div>
            })

            return <div key={index} className='word'>{renderedLetters}</div>;
        })
        const renderedLine = <div className='challenge-row active-challenge-row'> {arrayOfRenderedWords} </div>
        return renderedLine;
    }

    renderUpcomingWords(arrayOfLines) {
        const arrayOfRenderedLines = arrayOfLines.map((line, index) => {
            const arraysOfLetters = line.map((word, index) => {
                const arraysOfLetters = word.split('')
                if (index !== arrayOfLines.length - 1) { arraysOfLetters.push(' ') }
                return arraysOfLetters
            }, [])
            const arrayOfRenderedWords = arraysOfLetters.map((word, index) => {
                const renderedLetters = word.map((letter, index) => {
                    return <div key={index} className="challenge-letter">{letter}</div>
                })
                return <div key={index} className='word'>{renderedLetters}</div>;
            })
            const renderedLine = <ChallengeRow activeLine={this.state.activeLine} content={arrayOfRenderedWords} key={index}> {arrayOfRenderedWords} </ChallengeRow>
            return renderedLine;
        })

        return arrayOfRenderedLines
    }

    render() {
        return (
            <div className="typing-game-container">
                {this.props.gameOver ? (    
                    <motion.div exit="undefined">
                        { this.props.cleanGameState() } 
                        <Redirect to={`/postmatch`}></Redirect>
                    </motion.div>
                ) : <div></div>
                }

                <div className="heads-up">
                    <RoundedTitle>Practice Game</RoundedTitle>
                    <div className="status-bar">
                        <div className="stats-display">
                            <div className="wpm-display">
                                <div className="display-label">WPM</div>
                                <div className="counter wpm-counter">{(this.props.time === 60) ? 0 : Math.floor((this.props.accurateTo / 5) / ((60 - this.props.time) / 60) || 0)}</div>
                            </div>
                            <div className="time-display">
                                <div className="display-label">TIME</div>
                                <div className="counter time-counter">
                                    <span>{this.props.time}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='speed-typer-container'>
                    {(this.state.started) ? '' : <div className='start-game-prompt'>Time will start when you start typing. Take a deep breath and focus.</div>}
                    {this.props.challenge && this.state.lines[1] ? (
                        <div>
                            <div className="container old-words-container">
                                {this.renderOldWords(this.state.lines.slice(0, this.state.activeLine))}
                            </div>
                            <div className='old-words-gradient'></div>
                            <div className="container">
                                {this.renderActiveLine(this.state.lines[this.state.activeLine], this.props.accurateTo, this.props.syncedTo)}
                                {this.renderUpcomingWords(this.state.lines.slice(this.state.activeLine + 1, this.state.activeLine + 6))}
                            </div>
                        </div>
                    ) : (
                        this.props.error ? (
                            <div>{this.props.error}</div>
                        ) : (
                            <ClipLoader
                                css={override}
                                size={150}
                                color={"#123abc"}
                                loading={this.props.loading}
                            />)
                    )}
                </div>
                <div></div>
                <input autoComplete="off" ref={(input) => { this.mainInput = input; }} id="type-capture" className="type-capture even"></input>
            </div>
        );
    }
}

const actionCreators = {
    connectToSocket: userActions.connectToSocket,
    disconnectFromSocket: userActions.disconnectFromSocket,
    connectToGame: gameActions.connectToGame,
    createPracticeGame: userActions.createPracticeGame,
    authenticateGame: gameActions.authenticateGame,
    sendGameUpdate: gameActions.sendGameUpdate,
    updateGameState: gameActions.updateGameState,
    cleanGameState: gameActions.cleanGameState,
    updateTimer: gameActions.updateTimer,
    listenForGameEnd: gameActions.listenForGameEnd,
}

function mapStateToProps(state) {
    const mapStateToProps = {
    }

    mapStateToProps.time = state.updateTimer.time
    mapStateToProps.gameOver = state.updateGameState.gameOver
    mapStateToProps.accurateTo = state.updateGameState.accurateTo
    mapStateToProps.syncedTo = state.updateGameState.syncedTo

    if (state.connectToGame.game) {
        mapStateToProps.challenge = state.connectToGame.game.challenge.split(' ')
    }

    if (state.getWordlist.wordlist) {
        mapStateToProps.wordlist = state.getWordlist.wordlist.list.split(',')
    }

    return mapStateToProps
}

export default connect(mapStateToProps, actionCreators)(TypingGamePractice);
