export default function listenForGameEnd(state = {}, action) {
  switch(action.type) {
    case 'LISTEN_FOR_GAME_END':
      return {
        ...state,
        isSending: true,
      };
    case 'LISTEN_FOR_GAME_END_SUCCESS':
      return {
        isConnected: true,
        game: action.result, 
      }
    default: {
      return state;
    }
  }
}