import { combineReducers } from 'redux';

import { reducer as formReducer } from 'redux-form';
import registerUser from './registerUser';
import loginUser from './loginUser';
import authUser from './authUser';
import createPracticeGame from './createPracticeGame';
import createVersusGame from './createVersusGame';
import getWordlist from './getWordlist';
import updateGameState from './updateGameState';
import updateTimer from './updateTimer';
import endGame from './endGame';
import getArchivedGame from './getArchivedGame';
import getLeaderboard from './getLeaderboard';
import generateStatistics from './generateStatistics';
import connectToGame from './connectToGame';
import listenForGameEnd from './listenForGameEnd.js'
import setupVersusEvents from './setupVersusEvents';
import appWideEvents from './appWideEvents';
import getActiveUsers from './getActiveUsers';
import readyUpForVersus from './readyUpForVersus';

export default combineReducers({
  form: formReducer,
  registerUser,
  loginUser,
  authUser,
  createPracticeGame,
  createVersusGame,
  readyUpForVersus,
  getWordlist,
  updateGameState,
  updateTimer,
  endGame,
  getArchivedGame,
  generateStatistics,
  getLeaderboard,
  connectToGame,
  listenForGameEnd,
  setupVersusEvents,
  appWideEvents,
  getActiveUsers,
})