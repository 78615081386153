import React from 'react';
import { Redirect } from "react-router-dom";
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import RoundedTitle from '../atoms/RoundedTitle';
import ChallengeRow from '../atoms/ChallengeRow';
import userActions from '../../actions/user.actions';
import gameActions from '../../actions/game.actions';

import ClipLoader from "react-spinners/ClipLoader";

//Framer workaround
import { motion } from 'framer-motion'
import createPracticeGame from '../../reducers/createPracticeGame';
import { css } from "@emotion/core";
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: white;
`;

function divideIntoLines(words) {
    const lines = [];
    words.forEach((word) => {
        const wordWithSpace = `${word} `
        if (lines[0] && (sentenceLength(lines[0]) + wordWithSpace.length) <= 40) {
            lines[0].push(word)
        } else {
            lines.unshift([word])
        }
    })

    return lines.reverse();
}

function sentenceLength(arrayOfWords) {
    const length = arrayOfWords.reduce((acc, current) => {
        return acc += current.length + 1
    }, 0)
    return length;
}

function flattenToString(arrayOfWords) {
    return arrayOfWords.reduce((acc, val) => `${acc} ${val}`) + ' ';
}

function flattenArray(arrayOfLines) {
    return arrayOfLines.reduce((acc, val) => acc.concat(val))
}

function renderOldWords(arrayOfLines) {
    const arrayOfRenderedLines = arrayOfLines.map((line, index) => {
        const arraysOfLetters = line.map((word, index) => {
            const arraysOfLetters = word.split('')
            if (index !== arrayOfLines.length - 1) { arraysOfLetters.push(' ') }
            return arraysOfLetters
        }, [])
        const arrayOfRenderedWords = arraysOfLetters.map((word, index) => {
            const renderedLetters = word.map((letter, index) => {
                return <div key={index} className="challenge-letter">{letter}</div>
            })
            return <div key={index} className='word'>{renderedLetters}</div>;
        })

        const renderedLine = <div key={index} className='challenge-row'> {arrayOfRenderedWords} </div>
        return renderedLine;
    })

    return arrayOfRenderedLines
}

function renderActiveLine(line, accurateTo, syncedTo, completedLinesLength, currentAttempt) {
    let overallIndex = 0;
    const arrayOfRenderedWords = line.map((word, index) => {
        const arrayOfLetters = word.split('')
        arrayOfLetters.push(' ')

        const lineAccurateTo = accurateTo - completedLinesLength;
        const lineCurrentAttemptLength = currentAttempt.length - completedLinesLength;
        const renderedLetters = arrayOfLetters.map((letter, index) => {
            let classString = 'challenge-letter'
            if (overallIndex === lineCurrentAttemptLength) {
                classString = `active-letter ${classString}`
            }
            if (overallIndex < lineAccurateTo) {
                classString = `accurate ${classString}`
            }
            if (overallIndex >= lineAccurateTo && currentAttempt[overallIndex + completedLinesLength] && (overallIndex < syncedTo - completedLinesLength)) {
                classString = `inaccurate ${classString}`
            }

            overallIndex += 1;
            return <div key={index} className={classString}>{((overallIndex > lineAccurateTo && currentAttempt[overallIndex + completedLinesLength - 1]) ? currentAttempt[overallIndex + completedLinesLength - 1] : letter)}</div>
        })

        return <div key={index} className='word'>{renderedLetters}</div>;
    })
    const renderedLine = <div className='challenge-row active-challenge-row'> {arrayOfRenderedWords} </div>
    return renderedLine;
}

function renderUpcomingWords(arrayOfLines, activeLine) {
    const arrayOfRenderedLines = arrayOfLines.map((line, index) => {
        const arraysOfLetters = line.map((word, index) => {
            const arraysOfLetters = word.split('')
            if (index !== arrayOfLines.length - 1) { arraysOfLetters.push(' ') }
            return arraysOfLetters
        }, [])
        const arrayOfRenderedWords = arraysOfLetters.map((word, index) => {
            const renderedLetters = word.map((letter, index) => {
                return <div key={index} className="challenge-letter">{letter}</div>
            })
            return <div key={index} className='word'>{renderedLetters}</div>;
        })
        const renderedLine = <ChallengeRow activeLine={activeLine} content={arrayOfRenderedWords} key={index}> {arrayOfRenderedWords} </ChallengeRow>
        return renderedLine;
    })

    return arrayOfRenderedLines
}

function reducer(state, action) {
    switch (action.type) {
        case 'INITIALIZE_GAME_STATE':
            return { 
                ...state,
                lines: divideIntoLines(action.challenge.split(' ')),
            }
        case 'UPDATE_GAME_STATE':
            return {
                ...state,
                activeLine: action.activeLine,
                completedLinesLength: action.completedLinesLength,
                currentAttempt: action.currentAttempt,
            }
        case "GAME_STARTED":
            return {
                ...state,
                started: true
            }
        default:
            return;
    } 
        
}

function TypingGameVersus() {

    const history = useHistory();
    const dispatch = useDispatch();
    const { gameId } = useParams();

    // Local State
    const [state, localDispatch] = React.useReducer(reducer, { 
        currentAttempt: '',
        lines: [],
        activeLine: 0,
        completedLinesLength: 0,
        gameOver: false,
        started: false,
        timeoutID: null,
    });
    
    // Redux State
    const {
        time, 
        gameOver, 
        accurateTo, 
        syncedTo,
        challenge,
        started,
        opponentAccurateTo
    } = useSelector((state) => { 
        let _challenge = null;
        if (state.connectToGame.game) {
            _challenge = state.connectToGame.game.challenge
        }
        return {
            time: state.updateTimer.time, 
            gameOver: state.updateGameState.gameOver, 
            accurateTo: state.updateGameState.accurateTo, 
            syncedTo: state.updateGameState.syncedTo,
            challenge: _challenge,
            started: state.setupVersusEvents.started,
            opponentAccurateTo: state.setupVersusEvents.opponentAccurateTo,
        }}, 
    shallowEqual)

    React.useEffect(() => {
        if (started) {
            const gameEnd = new Date()
            gameEnd.setSeconds(gameEnd.getSeconds() + 60)
    
            const checkTimeout = () => {
                const now = new Date();
                if (now > gameEnd && state.timeoutID) {
                } else {
                    dispatch(gameActions.updateTimer(Math.ceil((gameEnd.getTime() - now.getTime()) / 1000)))
                    state.timeoutID = setTimeout(checkTimeout, 1000)
                }
            }
    
            state.timeoutID = setTimeout(checkTimeout, 1000)
            dispatch(gameActions.listenForGameEnd());
            localDispatch({ type: 'GAME_STARTED' });
        }
    }, [started])   

    React.useEffect(() => {
        const initializeGame = async () => {
            const jwt = localStorage.getItem('token');
            // the game id is in the url
            const game = await dispatch(gameActions.connectToGame(gameId))
            localDispatch({ type: 'INITIALIZE_GAME_STATE', challenge: game.result.challenge })

            if (game) {
                await dispatch({ type: 'CREATE_VERSUS_GAME_JOINED' })
            }

            dispatch(gameActions.updateTimer(60));
            const typeCapture = document.getElementsByClassName("type-capture")[0];
            dispatch(gameActions.setupVersusEvents());

            typeCapture.focus()
            const speedTyperContainer = document.querySelector('.speed-typer-container');
            speedTyperContainer.addEventListener('click', () => typeCapture.focus())
        }
        if (state.timeoutID) {
            clearTimeout(state.timeoutID);
        }
        if (!localStorage.getItem('token')) { history.push('/') }

        initializeGame();
    }, []);
    console.log(gameOver)
    React.useEffect(() => {
        if (gameOver) {
            dispatch(gameActions.cleanGameState())
            history.push('/postmatch')
        }
    }, [gameOver])

    const handleInput = async (inputObj) => {
        if (!state.started) {
            return
        }

        if (inputObj.isTrusted) {
            const wordArray = inputObj.target.value.split(' ');
            const activeLine = divideIntoLines(challenge.split(' ').slice(0, wordArray.length)).length - 1;
            const completedLinesLength = (activeLine > 0 ? flattenToString(flattenArray(state.lines.slice(0, activeLine))).length : 0)
            localDispatch({ type: 'UPDATE_GAME_STATE', activeLine, completedLinesLength, currentAttempt: inputObj.target.value })
            const response = await dispatch(gameActions.sendGameUpdate(gameId, inputObj.target.value))
            dispatch(gameActions.updateGameState(response))
        }
    }

    return (
        <div className="typing-game-container">
            {gameOver ? (    
                <motion.div exit="undefined">
                    {  } 
                    <Redirect to={`/postmatch`}></Redirect>
                </motion.div>
            ) : <div></div>
            }

            <div className="heads-up">
                <RoundedTitle>Practice Game</RoundedTitle>
                <div className="status-bar">
                    <div className="stats-display">
                        <div className="wpm-display">
                            <div className="display-label">WPM</div>
                            <div className="counter wpm-counter">{(time === 60) ? 0 : Math.floor((accurateTo / 5) / ((60 - time) / 60) || 0)}</div>
                        </div>
                        <div className="wpm-display">
                            <div className="display-label">Opponent WPM</div>
                            <div className="counter wpm-counter">{(time === 60) ? 0 : Math.floor((opponentAccurateTo / 5) / ((60 - time) / 60) || 0)}</div>
                        </div>
                        <div className="time-display">
                            <div className="display-label">TIME</div>
                            <div className="counter time-counter">
                                <span>{time}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='speed-typer-container'>
                {(state.started) ? 
                    '' : 
                    <div className='start-game-prompt'>
                        <button onClick={() => { dispatch(gameActions.readyUpForVersus(gameId)) } }>Ready Up?</button>
                    </div>
                }
                {challenge && state.lines[1] ? (
                    <div>
                        <div className="container old-words-container">
                            { renderOldWords(state.lines.slice(0, state.activeLine))}
                        </div>
                        <div className='old-words-gradient'></div>
                        <div className="container">
                            { renderActiveLine(state.lines[state.activeLine], accurateTo, syncedTo, state.completedLinesLength, state.currentAttempt)}
                            { renderUpcomingWords(state.lines.slice(state.activeLine + 1, state.activeLine + 6), state.activeLine)}
                        </div>
                    </div>
                ) : (
                    <ClipLoader
                        css={override}
                        size={150}
                        color={"#123abc"}
                        loading={true}
                    />
                )}
            </div>
            <div></div>
            <input autoComplete="off" id="type-capture" className="type-capture even" onInput={handleInput}></input>
        </div>
    );
}

export default TypingGameVersus;