export default function appWideEvents(state = {}, action) {
    switch(action.type) {
        case 'SETUP_APP_WIDE_EVENTS':
            return {
            ...state,
            isSending: true,
            };
        case 'SETUP_APP_WIDE_EVENTS_SUCCESS':
            return {
            ...state,
            isSending: false,
            }
        case 'SETUP_APP_WIDE_EVENTS_FAILURE':
            return {
                ...state,
                isSending: false,
                error: action.result
            }
        case 'GAME_REQUEST_TRIGGERED':
            return {
                ...state,
                gameRequest: true,
                gameRequestDetails: action.payload,
            }
        case 'GAME_REQUEST_ACCEPTED':
            return {
                ...state,
                gameRequest: false,
                gameRequestDetails: null,
            }
        case 'GAME_REQUEST_REJECTED':
            return {
                ...state,
                gameRequest: false,
                gameRequestDetails: null,
            }
      default: {
        return state;
      }
    }
  }