import React from 'react';
import { connect } from "react-redux";
import userActions from '../../actions/user.actions';
import getLeaderboard from '../../reducers/getLeaderboard';

class Leaderboard extends React.Component {
  componentDidMount() {
    this.props.getLeaderboard('practicegameleaderboard')
  }

  renderCards(leaderboard) {
    const cards = leaderboard.map((val, index) => {
      return (
        <div key={index} className='leaderboard-item'>
          <div className="leaderboard-number">{index + 1}</div>
          <div className="leaderboard-name">{val.value}</div>
          <div className="leaderboard-score">{val.score} <div className="leaderboard-wpm">WPM</div></div>
        </div>
      );
    })
    return cards;
  }

  render() {
    const { leaderboard } = this.props
    return (
      <div>
        { leaderboard && 
          <div>{this.renderCards(leaderboard)}</div>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const StateToProps = {}
  if (state.getLeaderboard) {
    StateToProps.leaderboard = state.getLeaderboard.leaderboard
  }
  return StateToProps;
}

const actionCreators = {
  getLeaderboard: userActions.getLeaderboard, 
}

export default connect(mapStateToProps, actionCreators)(Leaderboard);
