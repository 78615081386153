import React, { useCallback, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';

import LandingImageOne from '../../images/web_developer.svg'
import Expand from '../../images/expand_more-white-48dp.svg';
import Keyboard from './Keyboard';


function LandingSectionOne() {

    const [ pressed, setPressed ] = useState(new Set());

    const onPressKey = useCallback((value) => {
        setPressed((pressed) => new Set(pressed).add(value));
        setTimeout(() => {
            setPressed((pressed) => {
                const newPressed = new Set(pressed);
                newPressed.delete(value);
                return newPressed;
            });
        }, 225)
    }, []);

    const typeTitle = useCallback(() => {
        const title = document.querySelector('.landing-title-text');
        const text = title.innerHTML;
        title.innerHTML = '';
        let i = 0;
        const interval = setInterval(() => {
            if (i < text.length) {
                const currentLetter = text[i];
                title.innerHTML += currentLetter;
                if (currentLetter === ' ') { 
                    onPressKey('Space');
                } else {
                    onPressKey(text[i].toUpperCase());
                }
                i++;
            } else {
                clearInterval(interval);
            }
        }, 100)
    }, [])

    useEffect(() => {
        typeTitle();
    }, [])

    return (
        <div style={{height: '100%'}} className='LandingSectionOne'>
            <div className="landing-container landing-container-1">
                <div className="landing-left">
                    <div className="landing-text-container">
                        <div className="landing-title">
                            <span className="landing-title-text">SpeedTyper is the ad-free, social, type-racing game.</span>
                            <span className="blinking-cursor">|</span>
                        </div>
                        <div className="landing-text">We spend a LOT of time typing in the digital age. The faster we type, the more time we save. Have some fun, and save some time with SpeedTyper - the ad-free, social, type-racing platform.</div>

                        <ul>
                            <li>Play with your friends</li>
                            <li>Watch yourself improve</li>
                            <li>Utilize extensive performance statistics</li>
                            <li>Share replays of your matches, and race against your past self.</li>
                        </ul>
                    </div>
                    <div className="landing-buttons">
                        <Link to='/register'><div className="landing-button register-button button">Make an Account</div></Link>
                        <Link to='/login'><div className="landing-button login-button button">Log In</div></Link>
                    </div>
                </div>
                <div className="landing-right">
                    <div className="landing-illustration-1">
                        <Keyboard pressed={pressed} setPressed={setPressed} onPressKey={onPressKey}></Keyboard>
                    </div>
                    <div className="scroll-prompt"><img src={Expand}></img>Scroll down to learn more</div>
                </div>
            </div>
        </div>
    );
}

export default LandingSectionOne;
