import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import keyboard from '../../images/keyboard.png'
import cloud1 from '../../images/cloudy.png'
import cloud2 from '../../images/meteorology.png'
import Keyboard from './Keyboard';

function RegisterGraphic() {
    const [ pressed, setPressed ] = useState(new Set());

    const onPressKey = useCallback((value) => {
        setPressed((pressed) => new Set(pressed).add(value));
        setTimeout(() => {
            setPressed((pressed) => {
                const newPressed = new Set(pressed);
                newPressed.delete(value);
                return newPressed;
            });
        }, 200)
    }, []);
  return(
    <div className='graphic-container'>
        <Keyboard pressed={pressed} setPressed={setPressed} onPressKey={onPressKey}></Keyboard>
    </div>
  /*
    <div className="graphic-container">
      <div className="graphic-header">
          <div className="logo">SpeedTyper</div>
          <div className="header-text">is the best way to improve your typing speed</div>
        </div>
      <div className="landing-graphic">
        <div className="graphic-col graphic-col-1">
          <div className="line line-1"></div>
          <div className="text-line line-2">Typing fast saves time filing taxes, writing essays, and doing homework. (and just about everything)</div>
          <div className="line line-3"></div>
          <div className="line line-4"></div>
          <div className="line line-5"></div>
          <div className="line line-6"></div>
        </div>
        <div className="graphic-col graphic-col-2">
          <div className="line line-1"></div>
          <div className="line line-3"></div>
          <div className="line line-4"></div>
          <div className="line line-5"></div>
          <div className="text-line line-2">Play and learn with no distractions, no ads, and no fees. Free now and for always.</div>
          <div className="line line-6"></div>
        </div>
      </div>
    </div>*/
  );
}

export default RegisterGraphic;